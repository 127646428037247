<template>
  <div>
    <b-button
      class="manage-billing"
      variant="outline-success"
      @click="stripeCustomerPortal"
    >
      Manage billing
    </b-button>
    <div class="grid-3col">
      <div
        v-for="subscription in subscriptions"
        :key="subscription.id"
      >
        <Subscription class="max-width">
          <template v-slot:badge>
            <b-badge
              variant="light-danger"
              pill
            >
              {{ subscription.spots }} spots
            </b-badge>
          </template>
          <template v-slot:title>
            <h1>{{ subscription.title }}</h1>
            <p>{{ subscription.subtitle }}</p>
          </template>
          <template
            v-slot:price
          >
            <span
              class="old-price"
            >${{ subscription.price }}
            </span>
            <div
              v-if="subscription.priceDiscount === 0"
            >
              <span
                class="new-price"
              >/FREE</span>
              <sub>(for 6 months)</sub>
            </div>
            <div
              v-else
            >
              <span
                class="new-price"
              >/${{ subscription.priceDiscount }}</span>
              <sub
                class="pricing-duration
                        text-body
                        font-medium-1 font-weight-bold
                      "
              > per month</sub>
            </div>
          </template>
          <template v-slot:features>
            <div v-html="subscription.features" />
          </template>

          <template v-slot:button>
            <b-button
              class="manage-billing"
              variant="outline-success"
              @click="redirectStripeCheckout({ price: 'price_1JlQWxDhvT9geSyOPmRlRnhE' })"
            >
              Manage billing
            </b-button>
          </template>
        </Subscription>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
} from 'bootstrap-vue'
import { watch } from '@vue/composition-api'
// import BroadcastGrid from '@/views/components/broadcast/BroadcastGrid.vue'
import useBroadcasts from '@/composables/broadcasts/useBroadcasts'
import useCategories from '@/composables/useCategories'
import useUserSingle from '@/composables/users/useUserSingle'
import useSubscriptions from '@/composables/subscriptions/useSubscriptions'
import useStripe from '@/composables/stripe/useStripe'
import Ripple from 'vue-ripple-directive'
import Subscription from '../../components/subscription/appSubscription.vue'

export default {
  components: {
    BButton,
    BBadge,
    // BroadcastGrid,
    Subscription,
  },
  setup() {
    const { subscriptions, getSubscriptions } = useSubscriptions()
    getSubscriptions()
    const { user } = useUserSingle()
    const {
      categoriesList,
      setCategoryActive,
    } = useCategories()
    const { broadcasts } = useBroadcasts()
    const { redirectStripeCheckout, stripeCustomerPortal } = useStripe()

    watch(categoriesList, newCats => {
      setCategoryActive({ newCategory: newCats[5] })
    })

    return {
      user,
      broadcasts,
      subscriptions,
      redirectStripeCheckout,
      stripeCustomerPortal,
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      // subscription plans
    }
  },
}
</script>

<style lang="scss">
.pricing-value {
  font-size: 2.8rem;
}

.manage-billing {
  margin-bottom: 2rem;
}
.update-pricing {
  color: white !important;
  background-color: hsl(147, 67%, 47%);
  padding: 0.4rem 1rem 0.4rem 1rem;
  border-radius: 1rem;
  font-weight: 600;
}

.old-price {
  margin-bottom: 1rem;
  text-decoration: line-through;
}

</style>
