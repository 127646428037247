import {
  ref,
  computed,
} from '@vue/composition-api'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import snakeToCamel from '@/mixins/snakeToCamel'

const subscriptions = ref([])

export default function useSubscriptions() {
  const { http } = useApollo()
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT

  function processSubscription({ rawSub }) {
    const brd = []
    rawSub.forEach(a => {
      let singleSub = {}
      Object.keys(a).forEach(key => {
        const camelKey = snakeToCamel(key)
        singleSub[camelKey] = a[key]
      })
      if (!subscriptions.value.some(s => s.id === singleSub.id)) subscriptions.value.push(singleSub)
      singleSub = null
    })
    return brd
  }

  async function getSubscriptions() {
    await http.value.query({
      query: gql`
        query getSubscriptions {
          ${frontSchema}_subscriptions(
          order_by: {created_at: desc}) {
            id
            title
            subtitle
            features
            price
            price_discount
            coupon
            stripe_id
            spots
          }
        }
      `,
    })
      .then(result => {
        const rawSub = result.data[`${frontSchema}_subscriptions`]
        processSubscription({ rawSub })
      })
      .catch(err => console.error('getSubscriptions', err))
  }

  return {
    subscriptions: computed(() => subscriptions.value),
    getSubscriptions,
  }
}
