<template>
  <div
    class="m-card"
  >
    <slot />
  </div>
</template>

<script>

export default {
  // props: {
  //   index: {
  //     type: Number,
  //     default: 0,
  //   },
  // },
  // setup() {
  //   return {
  //     tabPage,
  //   }
  // },
}
</script>

<style lang="scss">

</style>
