<template>
  <Card class="subscription-wrap">
    <div>
      <div class="subscription-badge text-right">
        <slot name="badge" />
      </div>
      <div class="subscription-title">
        <slot name="title" />
      </div>

      <!-- title -->
      <div class="subscription-price">
        <slot name="price" />
      </div>
      <div class="subscription-body">

        <slot name="features" />
      </div>
    </div>

    <!-- buttons -->
    <button
      v-if="user.plan !== 'gold'"
      class="button primary"
      @click="redirectStripeCheckout({ price: 'price_1JlQWxDhvT9geSyOPmRlRnhE' })"
    >Upgrade</button>
    <button
      v-else
      class="button primary-outline"
    >Your current plan</button>
  </Card>
</template>

<script>

import useUserSingle from '@/composables/users/useUserSingle'
import useStripe from '@/composables/stripe/useStripe'
import Card from '../appCard.vue'

export default {
  setup() {
    const { redirectStripeCheckout } = useStripe()
    const { user } = useUserSingle()
    return {
      user,
      redirectStripeCheckout,
    }
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">

.update-pricing {
  color: white !important;
  background-color: hsl(147, 67%, 47%);
  padding: 0.4rem 1rem 0.4rem 1rem;
  border-radius: 1rem;
  font-weight: 600;
}

.old-price {
  margin-top: -10px;
  font-size: 1.4rem;
  text-decoration: line-through;
}

.new-price {
  margin-left: -4px;
  font-size: 2rem;
  font-weight: 600;
  color: hsl(147, 67%, 47%);;
}

.subscription-wrap {
  position: relative;
  width: 100%;
  min-width: 360px;
  max-width: 480px;
  display: flex;
  justify-content: start;
  // For Creation & Edit pages
  &.min-width {
    max-width: 360px !important;
  }
  .subscription-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;
  }
  .subscription-price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;
  }
  .subscription-body {
    padding: 1rem;
    margin-bottom: 2rem;
    li {
      padding-bottom: .5rem;
    }

  }

  .subscription-badge {
    margin-bottom: 1rem;
  }
}

</style>
